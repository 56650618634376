import React, { useState, useEffect } from 'react';
import Layout from '../../wrapperAux/wrapper'
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';
import axios from 'axios'

const ViafouraHistorical = () => {

	const [notifications, setNotifications] = useState([])
	const [_prevPage, setPrevPage] = useState(null)
	const [_nextPage, setNextPage] = useState(null)
	const [hasPrevPage, setHasPrevPage] = useState(false)
	const [hasNextPage, setHasNextPage] = useState(false)

	useEffect(() => {
		makeRequest(1)
	}, [])

	const makeRequest = async (page) => {
		try{
			const response = await axios.get(`${process.env.REACT_APP_BASE_API}/arc/viafoura/get-notifications/${page}`)
			const { docs, hasNextPage, hasPrevPage, nextPage, prevPage} = response.data
			setNotifications(docs)
			setPrevPage(prevPage)
			setNextPage(nextPage)
			setHasPrevPage(hasPrevPage)
			setHasNextPage(hasNextPage)
		} catch (e) {
			return null
		}		
	}

	const renderNotifications = () => {
		const htmlNotifications = []
		notifications.forEach((element, index) => {
			const { title, description, image_url, url, date } = element
			const row = (
				<tr key={index}>
					<td>{new Date(date).toISOString()}</td>
					<td>{title}</td>
					<td>{description}</td>
					<td>{image_url}</td>
					<td>{url}</td>
				</tr>
			)
			htmlNotifications.push(row)
		})
		return htmlNotifications
	}

	return (
		<Layout>
			<h1 className="mb-4">Histórico de notificaciones</h1>
			<Table responsive striped bordered hover size="sm">
				<thead>
					<tr>
						<th>Fecha</th>
						<th>Título</th>
						<th>Descripción</th>
						<th>Url de Imagen</th>
						<th>Url de nota</th>
					</tr>
				</thead>
				<tbody>
					{renderNotifications()}
				</tbody>
			</Table>
			<Pagination
				style={{
					textAlign: 'center',
					position: 'absolute',
					left: '50%',
					transform: 'translateX(-50%)'
				}}
			>
				<Pagination.Prev active disabled={!hasPrevPage} onClick={(e) => makeRequest(_prevPage)}/>
				<Pagination.Next active disabled={!hasNextPage} onClick={(e) => makeRequest(_nextPage)}/>
			</Pagination>
		</Layout>
	);

}

export default ViafouraHistorical;