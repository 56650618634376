import React, { Component } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap'
import Input from '../../components/Forms/InputType'
import Layout from '../../wrapperAux/wrapper'
import { astrologyForm } from './formStructure'
import axios from 'axios'

class Horoscope extends Component {

	state = {
		astrologyForm,
		validated: false,
		showModal: false,
		disabled: true
	}

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_BASE_API}/horoscope/display`)
			.then(res => this.fillData(Object.keys(res.data), res.data))
			.catch(err => console.log(err))
	}

	fillData = (arrData, data) => {
		const form = {
			...this.state.astrologyForm
		}
		for (const el of arrData) {
			if (el !== '_id' && el !== '__v') {
				const formElement = {
					...form[el]
				}
				formElement.text.value = data[el].text;
				formElement.simbol.value = data[el].daySimbol;
				form[el] = formElement;
			}
		}
		this.setState({ astrologyForm: form })
	}

	handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			const dataForm = {}
			for (const input in this.state.astrologyForm) {
				dataForm[input] = { 
					text: this.state.astrologyForm[input].text.value, 
					daySimbol: this.state.astrologyForm[input].simbol.value
				}
			}
			axios.post(`${process.env.REACT_APP_BASE_API}/horoscope/edit`, dataForm)
				.then(res => {
					if (res.status === 200) {
						this.setState({ showModal: true })
					}
				})
		}
		this.setState({ validated: true })
	}

	handleChange = (event, key, type) => {
		const form = {
			...this.state.astrologyForm
		}
		const formElement = {
			...form[key]
		}
		if (type === 'text'){
			formElement.text.value = event.target.value;
		}else if (type === 'simbol'){
			formElement.simbol.value = event.target.value;
		}
		
		form[key] = formElement
		this.setState({ astrologyForm: form })
	}

	renderCards = (inicio, fin, arrayForm, form) => {
		const array = [];
		for (let i = inicio; i < fin; i++) {
			array.push(
				<Col
					key={arrayForm[i]}
					xs={12}
					md={4}>
					<Card>
						<Card.Body>
							<Input
								htmlType={form[arrayForm[i]].text.htmlType}
								label={form[arrayForm[i]].text.label}
								config={form[arrayForm[i]].text.config}
								value={form[arrayForm[i]].text.value}
								change={(event) => this.handleChange(event, arrayForm[i], 'text')}
							/>
							<Input
								htmlType={form[arrayForm[i]].simbol.htmlType}
								label={form[arrayForm[i]].simbol.label}
								config={form[arrayForm[i]].simbol.config}
								value={form[arrayForm[i]].simbol.value}
								change={(event) => this.handleChange(event, arrayForm[i], 'simbol')}
							/>
						</Card.Body>
					</Card>
				</Col>
			)
		}
		return array
	}

	cleanFields = () => {
		let formElement = {}
		const dataform = {
			...this.state.astrologyForm
		}
		for (const info in this.state.astrologyForm){
			formElement = {
				...dataform[info]
			}
			formElement.text.value = '';
			formElement.simbol.value = '';
			dataform[info] = formElement
		}
		this.setState({astrologyForm: dataform})
	}

	editFields = () => {
		const disabled = this.state.disabled
		const newState = !disabled
		this.setState({ disabled: newState })
	}

	render() {
		const form = this.state.astrologyForm
		const arrayForm = Object.keys(form)
		return (
			<Layout>
				<h1 className="mb-4">Formulario de Horóscopo</h1>
				<Button
					variant={this.state.disabled ? "danger" : "primary"}
					className="mb-4"
					onClick={this.editFields}
				>
					{this.state.disabled ? 'Editar' : 'No editar'}
				</Button>
				<Button
					variant={this.state.disabled ? "secondary" : "danger"}
					className="ml-3 mb-4"
					onClick={this.cleanFields}
					disabled={this.state.disabled}
				>
					Limpiar todo
				</Button>
				<Form
					noValidate
					validated={this.state.validated}
					onSubmit={this.handleSubmit}
					className="mb-4"
				>
					<fieldset disabled={this.state.disabled}>
						<Row className="mb-4">{this.renderCards(0, 3, arrayForm, form)}</Row>
						<Row className="mb-4">{this.renderCards(3, 6, arrayForm, form)}</Row>
						<Row className="mb-4">{this.renderCards(6, 9, arrayForm, form)}</Row>
						<Row className="mb-4">{this.renderCards(9, 12, arrayForm, form)}</Row>
						
					</fieldset>
					<Button variant="primary" type="submit">
						Enviar
					</Button>
				</Form>
				<Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Mensaje</Modal.Title>
					</Modal.Header>
					<Modal.Body>Se editaron los datos</Modal.Body>
				</Modal>
			</Layout>
		);
	}
}

export default Horoscope;