import React, { useState } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap'
import Input from '../../components/Forms/InputType'
import Layout from '../../wrapperAux/wrapper'
import { viafouraFormFields } from './formStructure'
import axios from 'axios'

const ViafouraForm = () => {

	const [viafouraForm, setViafouraForm] = useState(viafouraFormFields);
	const [validated, setValidated] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in viafouraForm) {
				for (const input in viafouraForm[info]["opciones"]) {
					opciones[input] = viafouraForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const topic_id = dataForm.datos.opciones.broadcast_type === 'topic' ? {
				topic_id: dataForm.datos.opciones.topic_id
			} : {}
			const objectData = {
				"title": dataForm.datos.opciones.title,
				"description": dataForm.datos.opciones.description,
				"image_url": dataForm.datos.opciones.image_url,
				"url": dataForm.datos.opciones.url,
				"broadcast_type": dataForm.datos.opciones.broadcast_type,
				...topic_id
			}
			axios.post(`${process.env.REACT_APP_BASE_API}/arc/viafoura/sendNotification`, objectData)
				.then(res => {
					if (res.status === 200) {
						setShowModal(true)
					}
				})
				.catch(err => err)
		}
		setValidated(true)
	}

	const handleChange = (event, keyParent, key) => {
		const form = {
			...viafouraForm
		}
		const formElementParent = {
			...form[keyParent]
		}
		const formElement = {
			...formElementParent["opciones"][key]
		}
		formElement.value = event.target.value
		formElementParent["opciones"][key] = formElement
		form[keyParent] = formElementParent
		setViafouraForm(form)
	}

	const renderOneForm = (llave, formulario) => {
		let result = []
		const form = formulario

		const formKeys = form[llave]
		const formOptions = formKeys["opciones"]
		const arrayForm = Object.keys(formOptions)
		result = arrayForm.map(element =>
			<Input
				key={formOptions[element].config.id}
				htmlType={formOptions[element].htmlType}
				label={formOptions[element].label}
				config={formOptions[element].config}
				value={formOptions[element].value}
				change={(event) => handleChange(event, llave, element)}
			/>
		)

		return result
	}

	const renderForms = (inicio, fin, array, size) => {
		const form = viafouraForm
		const arrForms = []
		for (let i = inicio; i < fin; i++) {
			arrForms.push(
				<Col
					key={array[i]}
					xs={12}
					md={size}>
					<Card>
						<Card.Header><strong>{array[i].toString().toUpperCase()}</strong></Card.Header>
						<Card.Body>{renderOneForm(array[i], form)}</Card.Body>
					</Card>
				</Col>
			)
		}
		return arrForms
	}

	const cleanFields = () => {
		let formElementParent = {}
		let formElement = {}
		const dataFormCoronavirus = {
			...viafouraForm
		}
		for (const info in viafouraForm) {
			formElementParent = {
				...dataFormCoronavirus[info]
			}
			for (const input in viafouraForm[info]["opciones"]) {
				formElement = {
					...formElementParent["opciones"][input]
				}
				formElement.value = ''
				formElementParent["opciones"][input] = formElement
			}
			dataFormCoronavirus[info] = formElementParent
			formElement = {}
		}

		setViafouraForm(dataFormCoronavirus)
	}

	const formCoronavirus = viafouraForm
	const arrayFormCoronavirus = Object.keys(formCoronavirus)
	return (
		<Layout>
			<h1 className="mb-4">Formulario de envío de notificaciones para Viafoura</h1>
			<Button
				variant={"danger"}
				className="ml-3 mb-4"
				onClick={cleanFields}
			>
				Limpiar todo
			</Button>
			<Form
				noValidate
				validated={validated}
				onSubmit={handleSubmit}
				className="mb-4"
			>
				<fieldset >
					<Row className="mb-4">{renderForms(0, 1, arrayFormCoronavirus, 6)}</Row>
				</fieldset>
				<Button variant="primary" type="submit">
					Enviar
				</Button>
			</Form>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Mensaje</Modal.Title>
				</Modal.Header>
				<Modal.Body>Se editaron los datos</Modal.Body>
			</Modal>
		</Layout>
	);

}

export default ViafouraForm;