export const astrologyForm = {
	acuario: {
		text:{
			htmlType: 'textarea',
			label: 'Acuario',
			config: {
				id: 'acuario',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Acuario',
			config: {
				id: 'arcano_acuario',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	piscis: {
		text:{
			htmlType: 'textarea',
			label: 'Piscis',
			config: {
				id: 'piscis',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Picis',
			config: {
				id: 'arcano_picis',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	aries: {
		text:{
			htmlType: 'textarea',
			label: 'Aries',
			config: {
				id: 'aries',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Aries',
			config: {
				id: 'arcano_aries',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	tauro: {
		text:{
			htmlType: 'textarea',
			label: 'Tauro',
			config: {
				id: 'tauro',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Tauro',
			config: {
				id: 'arcano_tauro',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	geminis: {
		text:{
			htmlType: 'textarea',
			label: 'Géminis',
			config: {
				id: 'geminis',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Géminis',
			config: {
				id: 'arcano_geminis',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	cancer: {
		text:{
			htmlType: 'textarea',
			label: 'Cáncer',
			config: {
				id: 'cancer',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Cáncer',
			config: {
				id: 'arcano_cancer',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	leo: {
		text:{
			htmlType: 'textarea',
			label: 'Leo',
			config: {
				id: 'leo',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Leo',
			config: {
				id: 'arcano_leo',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	virgo: {
		text:{
			htmlType: 'textarea',
			label: 'Virgo',
			config: {
				id: 'virgo',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Virgo',
			config: {
				id: 'arcano_virgo',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	libra: {
		text:{
			htmlType: 'textarea',
			label: 'Libra',
			config: {
				id: 'libra',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Libra',
			config: {
				id: 'arcano_libra',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	escorpion: {
		text:{
			htmlType: 'textarea',
			label: 'Escorpion',
			config: {
				id: 'escorpion',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Escorpion',
			config: {
				id: 'arcano_escorpion',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	sagitario: {
		text:{
			htmlType: 'textarea',
			label: 'Sagitario',
			config: {
				id: 'sagitario',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Sagitario',
			config: {
				id: 'arcano_sagitario',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}
	},
	capricornio: {
		text:{
			htmlType: 'textarea',
			label: 'Capricornio',
			config: {
				id: 'capricornio',
				as: 'textarea',
				rows: '4',
				required: true
			},
			value: ''
		},
		simbol:{
			htmlType: 'input',
			label: 'Simbolo Capricornio',
			config: {
				id: 'arcano_capricornio',
				as: 'input',
				rows: '4',
				required: true
			},
			value: ''
		}		
	}
}