import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap'
import Input from '../../components/Forms/InputType'
import Layout from '../../wrapperAux/wrapper'
import { fixtureFormFields, searchFormFields, leagueFormFields } from './formStructure'
import axios from 'axios';

const FixtureForm = () => {

	const [fixtureForm, setFixtureForm] = useState(fixtureFormFields);
	const [searchForm, setSearchForm] = useState(searchFormFields);
	const [leagueForm, setLeagueForm] = useState(leagueFormFields);
	const [validated, setValidated] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [fixtures, setFixtures] = useState([]);
	const [leagues, setLeagues] = useState([]);

	useEffect(() => {
		async function fetchLeagues() {
			axios.get(`${process.env.REACT_APP_BASE_API}/league/`)
				.then((response) => {
					if (response.status === 200) {
						let currentLeagues = [];

						response.data.map(element => {
							currentLeagues.push({
								"value": element.ide ? element.ide : '',
								"display": element.name ? element.name : ''
							});
						});

						setLeagues(currentLeagues);
					}

				})
				.catch((error) => error);
		}
		fetchLeagues();
	}, []);

	const handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in fixtureForm) {
				for (const input in fixtureForm[info]["opciones"]) {
					opciones[input] = fixtureForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const objectData = {
				"team1": dataForm.datos.opciones.team1,
				"shield1": dataForm.datos.opciones.shield1,
				"team2": dataForm.datos.opciones.team2,
				"shield2": dataForm.datos.opciones.shield2,
				"league": dataForm.datos.opciones.league,
				"matchDate": dataForm.datos.opciones.matchDate,
				"matchDay": dataForm.datos.opciones.matchDay,
				"goals1": dataForm.datos.opciones.goals1,
				"goals2": dataForm.datos.opciones.goals2,
				"finished": dataForm.datos.opciones.finished
			};
			axios.post(`${process.env.REACT_APP_BASE_API}/fixture/`, objectData)
				.then(res => {
					if (res.status === 200) {
						setShowModal(true)
					}
				})
				.catch(err => err)
		}
		setValidated(true);
	}

	const handleChange = (event, keyParent, key, type) => {
		let form = {}

		if (type === 'insert-fixture') {
			form = { ...fixtureForm };
		} else if (type === 'search-fixture') {
			form = { ...searchForm };
		} else if (type === 'instert-league') {
			form = { ...leagueForm };
		}

		const formElementParent = {
			...form[keyParent]
		}
		const formElement = {
			...formElementParent["opciones"][key]
		}
		formElement.value = key === 'finished' ? event.target.checked : event.target.value;
		formElementParent["opciones"][key] = formElement;
		form[keyParent] = formElementParent;

		if (type === 'insert-fixture') {
			setFixtureForm(form);
		} else if (type === 'search-fixture') {
			setSearchForm(form);
		} else if (type === 'instert-league') {
			setLeagueForm(form);
		}
	}


	const renderOneForm = (llave, formulario, type) => {
		let result = [];
		const form = formulario;

		const formKeys = form[llave]
		const formOptions = formKeys["opciones"]
		const arrayForm = Object.keys(formOptions)
		result = arrayForm.map(element => {
			if (element === 'league') {
				formOptions[element].config.options = leagues;
			}

			return (<Input
				key={formOptions[element].config.id}
				htmlType={formOptions[element].htmlType}
				label={formOptions[element].label}
				config={formOptions[element].config}
				value={formOptions[element].value}
				change={(event) => handleChange(event, llave, element, type)}
			/>)
		});

		return result
	}

	const renderForms = (inicio, fin, array, size, type) => {
		let form = {}

		if (type === 'insert-fixture') {
			form = fixtureForm;
		} else if (type === 'search-fixture') {
			form = searchForm;
		} else if (type === 'instert-league') {
			form = leagueForm;
		}

		const arrForms = [];
		for (let i = inicio; i < fin; i++) {
			arrForms.push(
				<Col
					key={array[i]}
					xs={12}
					md={size}>
					<Card>
						<Card.Header><strong>{array[i].toString().toUpperCase()}</strong></Card.Header>
						<Card.Body>{renderOneForm(array[i], form, type)}</Card.Body>
					</Card>
				</Col>
			)
		}
		return arrForms
	}

	const cleanFields = () => {
		let formElementParent = {}
		let formElement = {}
		const dataFormFixture = {
			...fixtureForm
		}
		for (const info in fixtureForm) {
			formElementParent = {
				...dataFormFixture[info]
			}
			for (const input in fixtureForm[info]["opciones"]) {
				formElement = {
					...formElementParent["opciones"][input]
				}
				if (formElementParent["opciones"][input].htmlType !== 'select') {
					formElement.value = '';
				}
				formElementParent["opciones"][input] = formElement;
			}
			dataFormFixture[info] = formElementParent;
			formElement = {};
		}

		setFixtureForm(dataFormFixture)
	}

	const handleSearch = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in searchForm) {
				for (const input in searchForm[info]["opciones"]) {
					opciones[input] = searchForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const objectData = {
				"date1": dataForm.datos.opciones.date1,
				"date2": dataForm.datos.opciones.date2,
				"league": dataForm.datos.opciones.league,
			};

			axios.get(`${process.env.REACT_APP_BASE_API}/fixture/${objectData.date1}/${objectData.date2}/${objectData.league}`)
				.then((response) => {
					if (response.status === 200) {
						setFixtures(response.data);
						if (response.data.length > 0) {
							alert("Resultados encontrados");
						} else {
							alert("Ningún resultado encontrado");
						}
					}

				})
				.catch((error) => error);
		}
	}

	const handleSubmitLeague = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in leagueForm) {
				for (const input in leagueForm[info]["opciones"]) {
					opciones[input] = leagueForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const objectData = {
				"ide": dataForm.datos.opciones.ide,
				"name": dataForm.datos.opciones.name
			};
			axios.post(`${process.env.REACT_APP_BASE_API}/league/`, objectData)
				.then(res => {
					if (res.status === 200) {
						alert("Liga insertada correctamente");
						window.location.reload();
					}
				})
				.catch(err => err)
		}
	}

	const deleteFixture = (id = false) => {
		try {
			if (window.confirm("¿Está seguro que desea eliminar este partido?")) {
				axios.delete(`${process.env.REACT_APP_BASE_API}/fixture/${id}`)
					.then((response) => {
						if (response.status === 200) {
							alert("Partido Eliminado");
							window.location.reload();
						}
					})
					.catch((error) => error);
			}
		} catch (err) {
			console.log(err.response.data.msg);
		}
	};

	const formFixture = fixtureForm;
	const arrayFormFixture = Object.keys(formFixture);
	const searchFixture = searchForm;
	const arraySearchFormFixture = Object.keys(searchFixture);
	const formLeage = leagueForm;
	const arrayLeagueFormFixture = Object.keys(formLeage);

	return (
		<Layout>
			<h1 className="mb-4">Formulario Deportivo</h1>
			<h2>Insertar Partido</h2>
			<Button
				variant={"danger"}
				className="ml-3 mb-4"
				onClick={cleanFields}
			>
				Limpiar todo
			</Button>
			<Form
				noValidate
				validated={validated}
				onSubmit={handleSubmit}
				className="mb-4"
			>
				<fieldset >
					<Row className="mb-4">{renderForms(0, 1, arrayFormFixture, 6, 'insert-fixture')}</Row>
				</fieldset>
				<Button variant="primary" type="submit">
					Enviar
				</Button>
			</Form>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Mensaje</Modal.Title>
				</Modal.Header>
				<Modal.Body>Se enviaron los datos correctamente</Modal.Body>
			</Modal>
			<h2>Buscar Partidos</h2>
			<Form
				onSubmit={handleSearch}
				className="mb-4"
			>
				<fieldset >
					<Row className="mb-4">{renderForms(0, 1, arraySearchFormFixture, 6, 'search-fixture')}</Row>
				</fieldset>
				<Button variant="primary" type="submit">
					Buscar
				</Button>
			</Form>
			<table className="resultsTable">
				{fixtures.length !== 0 ?
					<tr>
						<th>Equipo 1</th>
						<th>Equipo 2</th>
						<th>Liga</th>
						<th>Fecha</th>
						<th>Jornada</th>
						<th>Goles 1</th>
						<th>Goles 2</th>
						<th>Estado</th>
					</tr>
					: null
				}
				{fixtures.map((fixture, i) => {
					return (
						<tr key={i}>
							<td>
								<p style={{ width: "200px" }}>{fixture.team1}</p>
							</td>
							<td>
								<p style={{ width: "200px" }}>{fixture.team2}</p>
							</td>
							<td>
								<p style={{ width: "200px" }}>{fixture.league}</p>
							</td>
							<td>
								<p style={{ width: "200px" }}>{fixture.matchDate}</p>
							</td>
							<td>
								<p style={{ width: "100px" }}>{fixture.matchDay}</p>
							</td>
							<td>
								<p style={{ width: "60px" }}>{fixture.goals1}</p>
							</td>
							<td>
								<p style={{ width: "60px" }}>{fixture.goals2}</p>
							</td>
							<td>
								<p style={{ width: "100px" }}>{fixture.finished ? 'finalizado' : 'No finalizado'}</p>
							</td>
							<td>
								<Link to={`/update-fixture?id=${fixture._id}`}>
									<Button variant="secondary">
										Actualizar
									</Button>
								</Link>
							</td>
							<td>
								<Button variant="danger" onClick={() => deleteFixture(fixture._id)}>
									Eliminar
								</Button>
							</td>
						</tr>
					)
				})}
			</table>
			<h2>Insertar Ligas</h2>
			<Form
				onSubmit={handleSubmitLeague}
				className="mb-4"
			>
				<fieldset >
					<Row className="mb-4">{renderForms(0, 1, arrayLeagueFormFixture, 6, 'instert-league')}</Row>
				</fieldset>
				<Button variant="primary" type="submit">
					Insertar
				</Button>
			</Form>
		</Layout>
	);

}

export default FixtureForm;