export const fixtureFormFields = {
	datos: {
		opciones:{
			team1: {
				htmlType: 'input',
				label: 'Equipo 1',
				config: {
					id: 'team1',
					type: 'text',
					placeholder: 'Inserte Nombre del Equipo 1',
					required: true
				},
				value: ''
			},
			shield1: {
				htmlType: 'input',
				label: 'Imagen del Escudo 1',
				config: {
					id: 'shield1',
					type: 'text',
					placeholder: 'Inserte URL',
					required: true
				},
				value: ''
			},
			team2: {
				htmlType: 'input',
				label: 'Equipo 2',
				config: {
					id: 'team2',
					type: 'text',
					placeholder: 'Inserte Nombre del Equipo 2',
					required: true
				},
				value: ''
			},
			shield2: {
				htmlType: 'input',
				label: 'Imagen del Escudo 2',
				config: {
					id: 'shield2',
					type: 'text',
					placeholder: 'Inserte URL',
					required: true
				},
				value: ''
			},
			league: {
				htmlType: 'select',
				label: "Liga",
				config: {
					id: 'league',
					type: 'select',
					options: [],
					required: true
				},
	
				value: ''
			},
			matchDate: {
				htmlType: 'datetime-local',
				label: 'Fecha del Partido',
				config: {
					id: 'matchDate',
					type: 'date',
					placeholder: 'Fecha del Partido',
				}
			},
			matchDay: {
				htmlType: 'input',
				label: 'Jornada',
				config: {
					id: 'matchDay',
					type: 'text',
					placeholder: 'Jornada',
				},
				value: ''
			},
			goals1: {
				htmlType: 'input',
				label: 'Goles del Primer Equipo',
				config: {
					id: 'goals1',
					type: 'text',
					placeholder: 'Goles del Primer Equipo',
				},
				value: ''
			},
			goals2: {
				htmlType: 'input',
				label: 'Goles del Segundo Equipo',
				config: {
					id: 'goals2',
					type: 'text',
					placeholder: 'Goles del Segundo Equipo',
				},
				value: ''
			},
			finished: {
				htmlType: 'checkbox',
				label: 'Partido Finalizado',
				config: {
					id: 'finished',
					type: 'checkbox',
					placeholder: 'Partido Finalizado',
				},
				value: false
			}
		}
	},
}

export const searchFormFields = {
	datos: {
		opciones:{
			league: {
				htmlType: 'select',
				label: "Liga",
				config: {
					id: 'league',
					type: 'select',
					options: [],
					required: true
				},
				value: ''
			},
			date1: {
				htmlType: 'datetime-local',
				label: 'Fecha 1',
				config: {
					id: 'date1',
					type: 'date',
					placeholder: 'Fecha 1',
				}
			},
			date2: {
				htmlType: 'datetime-local',
				label: 'Fecha 2',
				config: {
					id: 'date2',
					type: 'date',
					placeholder: 'Fecha 2',
				}
			}
		}
	},
}

export const leagueFormFields = {
	datos: {
		opciones:{
			ide: {
				htmlType: 'input',
				label: 'Identificador',
				config: {
					id: 'ide',
					type: 'text',
					placeholder: 'Ingrese el identificador de la liga',
				},
				value: ''
			},
			name: {
				htmlType: 'input',
				label: 'Nombre',
				config: {
					id: 'name',
					type: 'text',
					placeholder: 'Ingrese el nombre de la liga',
				},
				value: ''
			}
		}
	},
}