const sections = {
	Horoscope: {
		link: '/horoscopo',
		name: 'Horóscopo',
		description: 'Formulario que permite enviar los datos de la sección Horóscopo hacia la plataforma del Espectador'
	},
	Tarot: {
		link: '/tarot',
		name: 'Tarot',
		description: 'Formulario que permite enviar los datos de la sección Tarot hacia la plataforma del Espectador'
	},
	Economics: {
		link: '/economia',
		name: 'Datos económicos',
		description: 'Formulario que permite enviar los datos economicos del día para ser vistos en el portal del Espectador'
	},
	Coronavirus: {
		link: '/coronavirus',
		name: 'Coronavirus',
		description: 'Formulario para ingresar los datos del coronavirus'
	},
	Viafoura: {
		link: '/viafoura',
		name: 'Viafoura',
		description: 'Formulario para enviar notificaciones de Viafoura'
	},
	Fixture: {
		link: '/fixture',
		name: 'Modulo Deportivo',
		description: 'Formulario para el Módulo Deportivo'
	},
	ViafouraHistorical: {
		link: '/viafoura-historical',
		name: 'Histórico de notificaciones',
		description: 'Consulta de histórico de notificaciones enviadas'
	}
}

export const defineRole = ({role}) => {
    let sectionsObj = {}
    switch (role) {
        case 'admin':
            sectionsObj = {...sections}
            break;
        case 'tarot':
            sectionsObj = {Tarot: {...sections.Tarot}, Horoscope: {...sections.Horoscope}}
            break
        case 'economy':
            sectionsObj = {Economics: {...sections.Economics}}
            break
        case 'coronavirus':
            sectionsObj = {Coronavirus: {...sections.Coronavirus}}
            break
        case 'viafoura':
            sectionsObj = {Viafoura: {...sections.Viafoura}, ViafouraHistorical: {...sections.ViafouraHistorical}}
            break
		case 'fixture':
			sectionsObj = {Fixture: {...sections.Fixture}}
			break
        default:
            sectionsObj = {}
            break;
    }
    return sectionsObj
}