import React from 'react';
import Wrapper from '../../wrapperAux/wrapper'
import NavigationItems from '../Navigation/NavigationItems'
import { Container } from 'react-bootstrap'

const Layout = (props) => (
	<Wrapper>
		<NavigationItems />
		<Container className="mt-4">{props.children}</Container>
	</Wrapper>
);

export default Layout;