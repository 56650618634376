export const viafouraFormFields = {
	datos: {
		opciones:{
			title: {
				htmlType: 'input',
				label: 'Título de la notificación',
				config: {
					id: 'title',
					type: 'text',
					placeholder: 'Título',
					required: true
				},
				value: ''
			},
			description: {
				htmlType: 'input',
				label: 'Descripción de la notificación',
				config: {
					id: 'description',
					as: 'textarea',
					placeholder: 'Describa el mensaje',
					required: true
				},
				value: ''
			},
			image_url: {
				htmlType: 'input',
				label: 'Url de la imagen',
				config: {
					id: 'url_image',
					type: 'text',
					placeholder: 'Url de imagen',
					required: true
				},
				value: ''
			},
			url: {
				htmlType: 'input',
				label: 'Link',
				config: {
					id: 'url',
					type: 'text',
					placeholder: 'Link al dar clic',
					required: true
				},
				value: ''
			},
			broadcast_type: {
				htmlType: 'select',
				label: "Tipo de notificación",
				config: {
					id: 'broadcast_type',
					type: 'select',
					options: [
						{value: 'site', display: 'Todo el sitio'},
						{value: 'topic', display: 'Topico específico'},
					],
					required: true
				},
				value: 'site'
			},
			topic_id: {
				htmlType: 'input',
				label: 'Id de tópico (añadir solo si el tipo de notificación es tópico)',
				config: {
					id: 'topic_id',
					type: 'text',
					placeholder: 'Tópico de id',
				},
				value: ''
			}
		}
	},
}