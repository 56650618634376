import React, { useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap'
import axios from 'axios'

const Login = () => {

	const [user, setUser] = useState(null)
	const [password, setPassword] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [message, setMessage] = useState('')

	const handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			const objectData = {
				"user": user,
				"password": password
			}
			axios.post(`${process.env.REACT_APP_BASE_API}/forms/login`, objectData)
				.then(res => {
					if (res.status === 200) {
						document.cookie = `token=${res.data.token}; max-age=${300}; path=/; samesite=strict`
						localStorage.setItem('role', res.data.role)
						window.location.href = '/'
					}
				})
				.catch(err => {
					setMessage('Datos inválidos')
					setShowModal(true)
				})
		}
	}

	return (
		<Row>
			<Col xs={12} md={{ span: 6, offset: 3 }}>
				<h1 className="mt-3 mb-4">Inicia sesión</h1>
				<Form onSubmit={handleSubmit}>
					<Form.Group controlId="user">
						<Form.Label>Nombre de usuario</Form.Label>
						<Form.Control type="email" placeholder="Tu nombre de usuario" onChange={(event) => setUser(event.target.value)} required/>
					</Form.Group>
					<Form.Group controlId="password">
						<Form.Label>Contraseña</Form.Label>
						<Form.Control type="password" placeholder="Contraseña" onChange={(event) => setPassword(event.target.value)} required/>
					</Form.Group>
					<Button variant="primary" type="submit">
						Ingresar
					</Button>
				</Form>
				<Modal show={showModal} onHide={() => setShowModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Error</Modal.Title>
					</Modal.Header>
					<Modal.Body>{message}</Modal.Body>
				</Modal>
			</Col>
		</Row>
	);

}

export default Login;