import React, { Component } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap'
import Input from '../../components/Forms/InputType'
import Layout from '../../wrapperAux/wrapper'
import { coronavirusForm } from './formStructure'
import axios from 'axios'

class Coronavirus extends Component {

	state = {
		coronavirusForm,
		validated: false,
		showModal: false,
		disabled: true
	}

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_BASE_API}/coronavirus/display`)
			.then(res => this.fillDataForms(Object.keys(res.data), res.data))
			.catch(err => err)
	}

	fillDataForms = (arrData, data) => {		
		const form = {
			...this.state.coronavirusForm
		}

		for (const el of arrData) {
			if ((el !== '_id' && el !== '__v' && el !== 'actives')) {
				form.datos.opciones[el].value = data[el].value;
			}
		}		
		this.setState({ 
			coronavirusForm: form
		});
			
	}

	handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in this.state.coronavirusForm) {
				for (const input in this.state.coronavirusForm[info]["opciones"]) {
					opciones[input] = this.state.coronavirusForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const objectData = {
				"confirmedCases": {
					"value": dataForm.datos.opciones.confirmedCases
				},
				"recovered": {        
					"value": dataForm.datos.opciones.recovered
				},
				"dead": {
					"value": dataForm.datos.opciones.dead
				},
				"uciBeds": {
					"value": dataForm.datos.opciones.uciBeds
				}
			}
			axios.post(`${process.env.REACT_APP_BASE_API}/coronavirus/edit`, objectData)
				.then(res => {
					if (res.status === 200) {
						this.setState({ showModal: true })
					}
				})
				.catch(err => err)
		}
		this.setState({ validated: true })
	}

	handleChange = (event, keyParent, key) => {
		const form = {
			...this.state.coronavirusForm
		}
		const formElementParent = {
			...form[keyParent]
		}
		const formElement = {
			...formElementParent["opciones"][key]
		}
		formElement.value = event.target.value
		formElementParent["opciones"][key] = formElement
		form[keyParent] = formElementParent
		this.setState({ coronavirusForm: form })
	}

	renderOneForm = (llave, formulario) => {
		let result = []
		const form = formulario

		const formKeys = form[llave]
		const formOptions = formKeys["opciones"]
		const arrayForm = Object.keys(formOptions)
		result = arrayForm.map(element =>
			<Input
				key={formOptions[element].config.id}
				htmlType={formOptions[element].htmlType}
				label={formOptions[element].label}
				config={formOptions[element].config}
				value={formOptions[element].value}
				change={(event) => this.handleChange(event, llave, element)}
			/>
		)
		
		return result
	}

	renderForms = (inicio, fin, array, size) => {
		const form = this.state.coronavirusForm
		const arrForms = []
		for (let i = inicio; i < fin; i++) {
			arrForms.push(
				<Col
					key={array[i]}
					xs={12}
					md={size}>
					<Card>
						<Card.Header><strong>{array[i].toString().toUpperCase()}</strong></Card.Header>
						<Card.Body>{this.renderOneForm(array[i], form)}</Card.Body>
					</Card>
				</Col>
			)
		}
		return arrForms
	}

	cleanFields = () => {
		let formElementParent = {}
		let formElement = {}
		const dataFormCoronavirus = {
			...this.state.coronavirusForm
		}
		for (const info in this.state.coronavirusForm) {
			formElementParent = {
				...dataFormCoronavirus[info]
			}
			for (const input in this.state.coronavirusForm[info]["opciones"]) {
				formElement = {
					...formElementParent["opciones"][input]
				}
				formElement.value = ''
				formElementParent["opciones"][input] = formElement
			}
			dataFormCoronavirus[info] = formElementParent
			formElement = {}
		}
		
		this.setState({
			coronavirusForm: dataFormCoronavirus
		})
	}

	editFields = () => {
		const disabled = this.state.disabled
		const newState = !disabled
		this.setState({disabled: newState})
	}

	render() {
		const formCoronavirus = this.state.coronavirusForm
		const arrayFormCoronavirus = Object.keys(formCoronavirus)
		return (
			<Layout>
				<h1 className="mb-4">Formulario de Coronavirus</h1>
				<Button
					variant={this.state.disabled ? "danger" : "primary"}
					className="mb-4"
					onClick={this.editFields}
				>
					{this.state.disabled ? 'Editar' : 'No editar'}
				</Button>
				<Button
					variant={this.state.disabled ? "secondary" : "danger"}
					className="ml-3 mb-4"
					onClick={this.cleanFields}
					disabled={this.state.disabled}
				>
					Limpiar todo
				</Button>
				<Form
					noValidate
					validated={this.state.validated}
					onSubmit={this.handleSubmit}
					className="mb-4"
				>
					<fieldset disabled={this.state.disabled}>
						<Row className="mb-4">{this.renderForms(0, 1, arrayFormCoronavirus, 6)}</Row>
					</fieldset>
					<Button variant="primary" type="submit">
						Enviar
					</Button>
				</Form>
				<Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Mensaje</Modal.Title>
					</Modal.Header>
					<Modal.Body>Se editaron los datos</Modal.Body>
				</Modal>
			</Layout>
		);
	}
}

export default Coronavirus;