export const coronavirusForm = {
	datos: {
		opciones:{
			confirmedCases: {
				htmlType: 'input',
				label: 'Casos confirmados',
				config: {
					id: 'confirmedCases',
					type: 'number',
					placeholder: 'número de casos confirmados',
					required: true
				},
				value: ''
			},
			recovered: {
				htmlType: 'input',
				label: 'Recuperados',
				config: {
					id: 'recovered',
					type: 'number',
					placeholder: 'Número de recuperados',
					required: true
				},
				value: ''
			},
			dead: {
				htmlType: 'input',
				label: 'Fallecidos',
				config: {
					id: 'dead',
					type: 'number',
					placeholder: 'Número de fallecidos',
					required: true
				},
				value: ''
			},
			uciBeds: {
				htmlType: 'input',
				label: 'Ocupación Camas UCI',
				config: {
					id: 'uciBeds',
					type: 'number',
					placeholder: 'Porcentaje de ocupación',
					required: true
				},
				value: ''
			}
		}
	}
}