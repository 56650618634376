import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap'
import Wrapper from '../../wrapperAux/wrapper'
import { defineRole } from '../../assets/scripts/roles'

class Home extends Component {

	paintCards = () => {
		const isLogged = document.cookie.includes('token=')
		const role = localStorage.getItem('role')
		let cards = null
		if (isLogged && Boolean(role)) {
			const sectionsByRole = defineRole({ role })
			const arrSections = Object.keys(sectionsByRole)
			cards = arrSections.map(el =>
				<Card key={el} className="mb-4">
					<Card.Header as="h5">{sectionsByRole[el].name}</Card.Header>
					<Card.Body>
						<Card.Text>
							{sectionsByRole[el].description}
						</Card.Text>
						<Button variant="primary" onClick={() => window.location.href = sectionsByRole[el].link}>Ir a formulario</Button>
					</Card.Body>
				</Card>
			)
		}
		return cards
	}

	render() {
		return (
			<Wrapper>
				<h1 className="mb-4">Formularios</h1>
				{this.paintCards()}
			</Wrapper>
		);
	}
}

export default Home;