import React from 'react';
import { Navbar, Nav } from 'react-bootstrap'
import { defineRole } from '../../assets/scripts/roles'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/mobileLogo.svg'

const NavigationItems = (props) => {

	const paintSections = () => {
		const isLogged = document.cookie.includes('token=')
		const role = localStorage.getItem('role')
		let navbar = null
		if (isLogged && Boolean(role)) {
			const sectionsByRole = defineRole({role})
			const arrSections = Object.keys(sectionsByRole)
			navbar = arrSections.map(el => (
				<Link to={sectionsByRole[el].link} key={el} >
					<Navbar.Text className="mr-3">{sectionsByRole[el].name}</Navbar.Text>
				</Link>
			))
			navbar.push(<Nav className="ml-auto">
			<Nav.Link href="#" onClick={() => {document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; localStorage.removeItem('role'); window.location.href = '/login'}}>Salir</Nav.Link>
		</Nav>)
		}
		return navbar
	}

	return (<header>
		<Navbar bg="dark" expand="lg" variant="dark">
			<Link to="/">
				<Navbar.Brand>
					<img
						src={logo}
						width="40"
						height="30"
						className="d-inline-block align-top"
						alt="React Bootstrap logo"
					/>
				</Navbar.Brand>
			</Link>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					{paintSections()}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	</header>)
};

export default NavigationItems;