import React from 'react';
import { Form, FormGroup } from 'react-bootstrap'

const Input = (props) => {

	let jsxElement = null

	switch (props.htmlType) {
		case 'input':
			jsxElement = (
				<FormGroup>
					<Form.Label>{props.label}</Form.Label>
					<Form.Control
						{...props.config}
						value={props.value}
						onChange={props.change}
						style={{ fontSize: '13px' }}
					/>
				</FormGroup>)
			break;
		case 'radio':
			jsxElement = (
				<FormGroup>
					{props.config.options.map(el => <Form.Check
						key={el.id}
						{...el}
						onChange={props.change}
					/>)}
				</FormGroup>
			)
			break;
		case 'select':
			jsxElement = (
				<FormGroup>
					<Form.Label>{props.label}</Form.Label>
					<Form.Control
						as={props.htmlType}
						value={props.value}
						onChange={props.change}
						style={{ fontSize: '13px' }}
					>
						{props.config.options.map(el => <option key={el.value} value={el.value}>{el.display}</option>)}
					</Form.Control>
				</FormGroup>
			)
			break;
		case 'datetime-local':
			jsxElement = (
				<FormGroup>
					<Form.Label>{props.label}</Form.Label>
					<Form.Control
						type="datetime-local"
						value={props.value}
						onChange={props.change}
					/>
				</FormGroup>
			)
			break;
		case 'checkbox':
			jsxElement = (
				<FormGroup>
					<div className="checkboxInline">
						<Form.Label htmlFor={props.config.id} id={props.config.id+`-label`}>{props.label}</Form.Label>
						<Form.Control
							type="checkbox"
							id={props.config.id}
							checked={props.value}
							onChange={props.change}
						/>
					</div>
				</FormGroup>
			)
			break;
		default:
			jsxElement = (
				<FormGroup>
					<Form.Label>{props.label}</Form.Label>
					<Form.Control
						{...props.config}
						value={props.value}
						onChange={props.change}
						style={{ fontSize: '13px' }}
					/>
					<Form.Control.Feedback />
				</FormGroup>)
			break;
	}

	return (
		jsxElement
	);
}

export default Input;