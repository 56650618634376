import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Layout from './components/Layout/layout'
import Home from './containers/HomeContainer/Home'
import Horoscope from './containers/AstrologyContainer/Horoscope'
import Tarot from './containers/AstrologyContainer/Tarot'
import Economy from './containers/EconomicsContainer/Economics'
import Coronavirus from './containers/CoronavirusContainer/Coronavirus'
import Viafoura from './containers/ViafouraBroadcastContainer/Viafoura'
import Fixture from './containers/FixtureContainer/Fixture'
import UpdateFixture from './containers/FixtureContainer/UpdateFixture'
import ViafouraHistorical from './containers/ViafouraHistoricalContainer/ViafouraHistorical'
import Login from './containers/LoginContainer/Login'

function App() {

  const isLogged = document.cookie.includes('token=')
  
  return (
    <BrowserRouter>
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact render={() => !isLogged ? <Redirect to='/login' /> : <Home />} />
            <Route path="/login" render={() => !isLogged ? <Login /> : <Home />} />
            <Route path="/coronavirus" render={() => !isLogged ? <Redirect to='/login' /> : <Coronavirus />} />
            <Route path="/economia" render={() => !isLogged ? <Redirect to='/login' /> : <Economy />} />
            <Route path="/horoscopo" render={() => !isLogged ? <Redirect to='/login' /> : <Horoscope />} />
            <Route path="/tarot" render={() => !isLogged ? <Redirect to='/login' /> : <Tarot />} />
            <Route path="/viafoura" render={() => !isLogged ? <Redirect to='/login' /> : <Viafoura />} />
            <Route path="/fixture" render={() => !isLogged ? <Redirect to='/login' /> : <Fixture />} />
            <Route path="/update-fixture" render={() => !isLogged ? <Redirect to='/login' /> : <UpdateFixture />} />
            <Route path="/viafoura-historical" render={() => !isLogged ? <Redirect to='/login' /> : <ViafouraHistorical />} />
          </Switch>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
