import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Input from '../../components/Forms/InputType';
import Layout from '../../wrapperAux/wrapper';
import { fixtureFormFields } from './formStructure';
import axios from 'axios';

const UpdateFixtureForm = () => {

	const [fixtureForm, setFixtureForm] = useState(fixtureFormFields);
	const [fixture, setFixture] = useState({});
	const params = new URLSearchParams(window.location.search);
	const id = params.get('id');
	const [validated, setValidated] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		async function fetchFixture() {
			axios.get(`${process.env.REACT_APP_BASE_API}/fixture/findone/${id}`)
				.then((response) => {
					if (response.status === 200) {
						setFixture(response.data);
					}

				})
				.catch((error) => error);
		}
		fetchFixture();
	}, [id])

	useEffect(() => {
		fillFields();
	}, [fixture])

	const convertDate = (isodate) => {
		if (isodate) {
			let [yyyy, mm, dd, hh, mi] = isodate.split(/[/:\-T]/);
			return (`${yyyy}-${mm}-${dd}T${hh}:${mi}`);
		}
	}

	const fillFields = () =>{
		if (fixture) {
			console.log('Fixture field', fixture);
			let formElementParent = {}
			let formElement = {}
			const dataFormFixture = {
				...fixtureForm
			}
			for (const info in fixtureForm) {
				formElementParent = {
					...dataFormFixture[info]
				}
				for (const input in fixtureForm[info]["opciones"]) {
					formElementParent["opciones"][input].value = input === 'matchDate' ? convertDate(fixture[input]) : fixture[input];
					formElement = {
						...formElementParent["opciones"][input]
					}
					formElementParent["opciones"][input] = formElement;
				}
				dataFormFixture[info] = formElementParent;
				console.log('Final dataform', dataFormFixture[info]);
				formElement = {};
			}

			setFixtureForm(dataFormFixture);
		}
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in fixtureForm) {
				for (const input in fixtureForm[info]["opciones"]) {
					opciones[input] = fixtureForm[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			const objectData = {
				"id": fixture._id,
				"team1": dataForm.datos.opciones.team1,
				"shield1": dataForm.datos.opciones.shield1,
				"team2": dataForm.datos.opciones.team2,
				"shield2": dataForm.datos.opciones.shield2,
				"league": dataForm.datos.opciones.league,
				"matchDate": dataForm.datos.opciones.matchDate,
				"matchDay": dataForm.datos.opciones.matchDay,
				"goals1": dataForm.datos.opciones.goals1,
				"goals2": dataForm.datos.opciones.goals2,
				"finished": dataForm.datos.opciones.finished,
			};
			axios.put(`${process.env.REACT_APP_BASE_API}/fixture/`, objectData)
				.then(res => {
					if (res.status === 200) {
						setShowModal(true)
					}
				})
				.catch(err => err)
		}
		setValidated(true);
	}

	const handleChange = (event, keyParent, key) => {
		const form = { ...fixtureForm };
		const formElementParent = {
			...form[keyParent]
		};
		const formElement = {
			...formElementParent["opciones"][key]
		};
		formElement.value = key === 'finished' ? event.target.checked : event.target.value;
		formElementParent["opciones"][key] = formElement;
		form[keyParent] = formElementParent;
		setFixtureForm(form);
	}


	const renderOneForm = (llave, formulario) => {
		let result = [];
		const form = formulario;

		const formKeys = form[llave];
		let formOptions = formKeys["opciones"];
		const arrayForm = Object.keys(formOptions)

		result = arrayForm.map(element =>
			<Input
				key={formOptions[element].config.id}
				htmlType={formOptions[element].htmlType}
				label={formOptions[element].label}
				config={formOptions[element].config}
				value={formOptions[element].value}
				change={(event) => handleChange(event, llave, element)}
			/>
		)

		return result
	}

	const renderForms = (inicio, fin, array, size) => {
		const form = fixtureForm;
		const arrForms = [];
		for (let i = inicio; i < fin; i++) {
			arrForms.push(
				<Col
					key={array[i]}
					xs={12}
					md={size}>
					<Card>
						<Card.Header><strong>{array[i].toString().toUpperCase()}</strong></Card.Header>
						<Card.Body>{renderOneForm(array[i], form)}</Card.Body>
					</Card>
				</Col>
			)
		}
		return arrForms
	}

	const cleanFields = () => {
		let formElementParent = {}
		let formElement = {}
		const dataFormFixture = {
			...fixtureForm
		}
		for (const info in fixtureForm) {
			formElementParent = {
				...dataFormFixture[info]
			}
			for (const input in fixtureForm[info]["opciones"]) {
				formElement = {
					...formElementParent["opciones"][input]
				}
				if (formElementParent["opciones"][input].htmlType !== 'select') {
					formElement.value = '';
				}
				formElementParent["opciones"][input] = formElement;
			}
			dataFormFixture[info] = formElementParent;
			formElement = {};
		}
		setFixtureForm(dataFormFixture)
	}

	const formFixture = fixtureForm;
	const arrayFormFixture = Object.keys(formFixture);

	return (
		<Layout>
			<h1 className="mb-4">Formulario Deportivo</h1>
			<Button
				variant={"danger"}
				className="ml-3 mb-4"
				onClick={cleanFields}
			>
				Limpiar todo
			</Button>
			<Form
				noValidate
				validated={validated}
				onSubmit={handleSubmit}
				className="mb-4"
			>
				<fieldset >
					<Row className="mb-4">{renderForms(0, 1, arrayFormFixture, 10)}</Row>
				</fieldset>
				<Button variant="secondary" type="submit">
					Actualizar
				</Button>
				
				<Link to={`/fixture`}>
					<Button variant="primary" className="ml-3">
						Volver
					</Button>
				</Link>
			</Form>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Mensaje</Modal.Title>
				</Modal.Header>
				<Modal.Body>Se actualizaron los datos correctamente</Modal.Body>
			</Modal>
		</Layout>
	);

}

export default UpdateFixtureForm;